import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const EarthsCore = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta
          name="twitter:title"
          content="Shoya, Inc. to Purchase Earth's Core"
        />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Shoya, Inc. to Purchase Earth's Core</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Aug. 17, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            While many of the world's most notorious billionaires (namely the
            Delivery Boy, Weird Accent Guy, and The Virgin) look to the stars to
            compete for interplanetary glory, the world's sole{" "}
            <i>trillionaire</i> pats them on the head condescendingly while
            setting his sights in the total opposite direction.
          </p>
          <p>
            In a rare press conference held on the morning of August 16th, the
            CEO of Shoya, Inc. <i>himself</i> appeared to shed some (albeit dim)
            light on the strategy behind this unthinkable transaction. The
            conference was organized at the base of the Yellowstone
            Supervolcano, and attended by the world's most respected thinkers
            and journalists.
          </p>
          <p>
            The Q&A was the only event, there was no public presentation on the
            matter. The format was mostly standard, though somewhat odd: Mr.
            Shoya was sat beside the genius actor Michael K. Williams, both
            matching in gorpcore's finest offerings. The CEO accepted questions,
            but only relayed answers via whisper to the actor, who then
            articulated them to those present. The two were flanked by a
            half-dozen standing scientists, most in white coats (though the one
            closest to Mr. Shoya wore one of a{" "}
            <a
              href="http://shoya.co/shoyaright/vol1/mt-shoya/"
              target="_blank"
              rel="noopener noreferrer"
            >
              grey-green-purple
            </a>{" "}
            variety).
          </p>
          <p>
            Jake Tapper, CNN: <span className="editorsNote">...why?</span>
            <br /> Flexing is my sexual orientation, next question.
          </p>
          <p>
            Sheera Frenkel, NYT:{" "}
            <span className="editorsNote">
              Earth's core is completely molten, what exactly do you plan to do
              about that?
            </span>{" "}
            <br />
            We just gon scoop all that shit out, tf? ... <br />
            <br /> [The lead scientist then whispered in the CEO's ear, who in
            turn whispered into the actor's ear.]
            <br />
            <br /> ... Or we might suck everything out with like a big straw or
            something, still some minor details we gotta flesh out. Next
            question.
          </p>
          <p>
            Kris Rhim, Boston Globe:{" "}
            <span className="editorsNote">
              Many people already refer to you as the 'Michael Jordan of Having'
              -- is terraforming just your version of a victory lap, or do you
              feel this gives you a real edge over your would-be competitors?
            </span>{" "}
            <br /> Excellent question, Kris!!! I love you bro, it's been a
            pleasure{" "}
            <a
              href="https://twitter.com/krisrhim1"
              target="_blank"
              rel="noopener noreferrer"
            >
              watching you grow as a journalist
            </a>{" "}
            and I look forward to your continued success.
          </p>
          <p>
            Chanel Rion, One America News:{" "}
            <span className="editorsNote">Great job, sir.</span> <br /> Next
            question.
          </p>
          <p>
            Eugene Puryear, Breakthrough News:{" "}
            <span className="editorsNote">Isn't this a bad idea given--</span>{" "}
            <br /> SHUT THE FUCK UP EUGENE FUCK who tf even INVITED YOU?! Next
            question.
          </p>
          <p>
            Malala Yousafzai:{" "}
            <span className="editorsNote">What about the children?</span> <br />{" "}
            C'mon now Malala cut me some slack here.
          </p>
          <p>
            [The session was then interrupted by some commotion toward the rear
            of the event, scored by gasps, whispers, and camera shutters. An
            elder wearing village clothes and bushy grey hair approached, and
            spoke with a{" "}
            <a
              href="https://www.youtube.com/watch?v=weWTuvdL-LQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              voice like Barry White's
            </a>
            .]
          </p>
          <p>
            Anonymous:{" "}
            <span className="editorsNote">
              I have a question, my child, and it's one you've heard before.
              When Adam delved, and Eve span, who then was the Gentleman?{" "}
            </span>
          </p>
          <p>
            [Big Shoya suppressed a look of disgust and tugged twice on his
            earlobe. Two men promptly escorted the stranger away.]
          </p>
          <p>And then, some say, the CEO wiped away a tear.</p>
          <p>
            <span className="editorsNote">Addendum: </span>This article was
            published in its exact form approximately 3 weeks prior to the news
            of Michael K. Williams' passing. I'm grateful to have expressed my
            admiration for him while he was still with us, and pray no
            disrespect.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default EarthsCore
